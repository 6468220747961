/* eslint-disable @typescript-eslint/no-use-before-define */
import { takeEvery, all, call, put, select, takeLatest, debounce } from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'

import { getProducts } from 'src/api/products'
import {
  EventInfo,
  EventModel,
  EventOrder,
  HardGood,
  Product,
  RecipeHardGood,
  ReducerProduct,
  Stem,
  StemOrderUnit
} from 'src/common/common.interface'
import {
  detectedDifferenceRecipesByEventId,
  getEventData,
  getStyleInformationByEventId,
  IDifferenceRecipes
} from 'src/api/getEventData'
import { EventOrderResponse, postOrders } from 'src/api/postOrders'
import {
  createRecipe,
  CreateRecipesResponse,
  deleteRecipe,
  QuantityMode,
  updateRecipe,
  UpdateRecipeMultiPayload,
  updateMultiRecipes
} from 'src/api/recipes'
import { addRecipeStem, AddRecipeStemPayloadResponse, deleteRecipeStem, updateRecipeStem } from 'src/api/recipeStems'
import { appAction } from 'src/store/app'

import {
  AddRecipeHardGoodPayload,
  addRecipeHardGoodApi,
  deleteRecipeHardGoodApi,
  updateRecipeHardGoodApi
} from 'src/api/recipeHardGoods'
import { getProductDefaultImageUrl } from 'src/common/util'
import { RecipeFormulaStemRole } from 'src/common/enum'
import { recipeAction, UpdateHardGoodPayload, UpdateStemPayload } from './recipe.slice'
import { selectorEventOrderId, selectorProducts } from './recipe.selector'
import {
  convertIRecipeStemToStemOrderUnit,
  convertStemOrderUnitToRecipeStemPayload,
  getUnitsNeeded,
  productPrice
} from './helpers'

export const DEBOUNCE_CALL_API_UPDATE = 2000

interface IRecipeCallAPI {
  eventId: number
}

interface IUpdateRecipe {
  recipeId: number
}

interface IStyleInformation {
  eventId: number
}

export interface IAddRecipeStemPayload {
  product: ReducerProduct
  stem: Stem
}

export interface IRemoveRecipeStemPayload {
  product: ReducerProduct
  stem: StemOrderUnit
}

export interface IUpdateRecipeStemPayload {
  product: ReducerProduct
  stem: StemOrderUnit
  stemNum?: number
}

export interface IAddRecipeHardGoodPayload {
  product: ReducerProduct
  hardGoods: HardGood[]
  existQuantityType?: string
}

export interface IUpdateRecipeHardGoodPayload {
  product: ReducerProduct
  hardGood: HardGood
  quantity: number
}

export interface IRemoveRecipeHardGoodPayload {
  product: ReducerProduct
  recipeHardGood: RecipeHardGood
}

export interface IUpdateRecipeHardGoodAPIPayload {
  product: ReducerProduct
  recipeHardGood: RecipeHardGood
  quantity?: number
  quantityType?: string
}

export function* getDetectedDifferenceRecipeSaga(eventId: number) {
  try {
    const response: IDifferenceRecipes = yield call(detectedDifferenceRecipesByEventId, eventId)

    yield put(recipeAction.setDetectedDifferenceRecipe(response))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getDetectedDifferenceRecipeSaga', error)
  }
}

export function* getProductsSaga() {
  try {
    yield put(recipeAction.setLoadingProductData(true))

    const response: Product[] = yield call(getProducts)

    yield put(recipeAction.addProductsData(response))
    yield put(recipeAction.setLoadingProductData(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProductsSaga', error)
  }
}

export function* getEventDataSaga(eventId: number) {
  try {
    const response: EventInfo = yield call(getEventData, eventId)
    yield put(recipeAction.addEventData(response))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getEventDataSaga', error)
  }
}

export function* getStyleInformationSaga(eventId: number) {
  try {
    const response: EventModel = yield call(getStyleInformationByEventId, eventId)
    yield put(recipeAction.addStyleInformationData(response))
  } catch (error) {
    yield put(recipeAction.addStyleInformationData({ customer: {} } as EventModel))
    // eslint-disable-next-line no-console
    console.error('error getStyleInformationSaga', error)
  }
}

export function* postOrdersSaga(eventId: number) {
  try {
    const response: EventOrderResponse = yield call(postOrders, eventId)

    yield put(recipeAction.addEventOrderId(response))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error postOrdersSaga', error)
  }
}

function* updateRecipeSequenceNumSaga() {
  try {
    yield put(recipeAction.setSavingDataCount(1))
    const reducerProducts: ReducerProduct[] = yield select(selectorProducts)
    const eventOrderId: number = yield select(selectorEventOrderId)

    const payload: UpdateRecipeMultiPayload = {
      recipes: reducerProducts.map((item, index) => ({
        recipeId: item.recipeId ?? item.id,
        sequenceNum: index
      }))
    }

    yield call(updateMultiRecipes, eventOrderId, payload)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateRecipeSequenceNumSaga', error)
  }
  yield put(recipeAction.setSavingDataCount(-1))
}

function* updateMultiRecipesForAssignsFormulaAndProductTypeSaga(payload: UpdateRecipeMultiPayload) {
  try {
    yield put(recipeAction.setSavingDataCount(1))
    const eventOrderId: number = yield select(selectorEventOrderId)
    const products: ReducerProduct[] = yield select(selectorProducts)

    const response: EventOrder = yield call(updateMultiRecipes, eventOrderId, payload)
    const newProducts: ReducerProduct[] = products.map((product) => {
      const recipe = response.recipes.find((r) => r.id === product.recipeId)
      if (!recipe) return product

      return {
        ...product,
        sequenceNum: recipe.sequenceNum,
        approved: recipe.approved,
        productTypeId: recipe.productTypeId,
        formulaId: recipe.formulaId,
        styleNotes: recipe.styleNotes ?? '',
        prepTimeMinutes: recipe.prepTimeMinutes,
        designTimeMinutes: recipe.designTimeMinutes,
        installTimeMinutes: recipe.installTimeMinutes,
        loadingTimeMinutes: recipe.loadingTimeMinutes,
        photosNeeded: recipe.photosNeeded ?? [],
        stems: recipe.recipeStems?.map((rs) => {
          return {
            ...rs,
            ...rs?.stem,
            stemsNeeded: rs?.quantity || 1,
            bunchesNeeded: getUnitsNeeded(rs.stem, rs.quantity),
            totalStemPrice:
              product?.quantityMode && product?.quantityMode === QuantityMode.ALL
                ? +(rs.stem.pricePerStem || 0)
                : +(rs.stem.pricePerStem || 0) * +(rs.quantity || 0),
            stemRole: rs?.stemRole as RecipeFormulaStemRole,
            roleIndex: rs?.roleIndex || 1,
            recipeStemId: rs?.id || -1,
            supplierStem: rs.stem.supplierStem,
            supplierStemId: rs.stem.supplierStemId
          }
        }),
        recipeHardGoods: recipe.recipeHardGoods
      }
    })
    yield put(recipeAction.setReducerProductList(newProducts))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateMultiRecipesForAssignsFormulaAndProductTypeSaga', error)
  }
  yield put(recipeAction.setSavingDataCount(-1))
}

function* recipeCallAPISaga({ payload }: ReturnType<typeof recipeActionSaga.recipeCallAPI>) {
  const { eventId } = payload

  yield put(appAction.startLoading())

  yield all([call(getEventDataSaga, eventId), call(postOrdersSaga, eventId)])

  yield put(appAction.endLoading())
}

function* createRecipeSaga({ payload: newProduct }: ReturnType<typeof recipeActionSaga.createRecipe>) {
  try {
    let products: ReducerProduct[] = yield select(selectorProducts)
    products = [...products].sort((p1, p2) => (p1.sequenceNum || 0) - (p2.sequenceNum || 0))
    const maxSequenceNum = products?.pop()?.sequenceNum
    const reducerProductRaw: ReducerProduct = {
      name: newProduct.name,
      id: newProduct.id,
      unitProductPrice: '',
      quantity: 0,
      sequenceNum: maxSequenceNum || maxSequenceNum === 0 ? maxSequenceNum + 1 : 0,
      prepTimeMinutes: newProduct.variants[0]?.defaultPrepTimeMinutes
        ? +newProduct.variants[0]?.defaultPrepTimeMinutes
        : undefined,
      designTimeMinutes: newProduct.variants[0]?.defaultDesignTimeMinutes
        ? +newProduct.variants[0]?.defaultDesignTimeMinutes
        : undefined,
      installTimeMinutes: newProduct.variants[0]?.defaultInstallTimeMinutes
        ? +newProduct.variants[0]?.defaultInstallTimeMinutes
        : undefined,
      loadingTimeMinutes: newProduct.variants[0]?.defaultLoadingTimeMinutes
        ? +newProduct.variants[0]?.defaultLoadingTimeMinutes
        : undefined,
      styleNotes: newProduct.variants[0]?.defaultInstructions ?? '',
      photosNeeded: newProduct.variants[0]?.defaultPhotosNeeded ?? undefined,
      imageUrl: getProductDefaultImageUrl(newProduct?.images || []) ?? '',
      price: productPrice(newProduct),
      stems: [],
      recipeHardGoods: [],
      images: [],
      variants: [],
      unitProduct: null
    }

    const eventOrderId: number = yield select(selectorEventOrderId)
    yield put(recipeAction.setSavingDataCount(1))
    yield put(recipeAction.addProduct(reducerProductRaw))

    const response: CreateRecipesResponse = yield call(createRecipe, {
      eventOrderId,
      productId: newProduct.id,
      quantity: 1,
      sequenceNum: maxSequenceNum || maxSequenceNum === 0 ? maxSequenceNum + 1 : 0,
      prepTimeMinutes: newProduct.variants[0]?.defaultPrepTimeMinutes
        ? +newProduct.variants[0]?.defaultPrepTimeMinutes
        : undefined,
      designTimeMinutes: newProduct.variants[0]?.defaultDesignTimeMinutes
        ? +newProduct.variants[0]?.defaultDesignTimeMinutes
        : undefined,
      installTimeMinutes: newProduct.variants[0]?.defaultInstallTimeMinutes
        ? +newProduct.variants[0]?.defaultInstallTimeMinutes
        : undefined,
      loadingTimeMinutes: newProduct.variants[0]?.defaultLoadingTimeMinutes
        ? +newProduct.variants[0]?.defaultLoadingTimeMinutes
        : undefined,
      styleNotes: newProduct.variants[0]?.defaultInstructions ?? '',
      photosNeeded: newProduct.variants[0]?.defaultPhotosNeeded ?? undefined
    })

    const stemOrderUnits = convertIRecipeStemToStemOrderUnit(response.recipeStems)

    const reducerProduct: ReducerProduct = {
      ...reducerProductRaw,
      recipeId: response.id,
      quantity: response.quantity,
      sequenceNum: response.sequenceNum,
      stems: stemOrderUnits,
      recipeHardGoods: response.recipeHardGoods,
      productDetails: response.product.productDetails
    }

    yield put(recipeAction.addProduct(reducerProduct))
    let newProducts: ReducerProduct[] = yield select(selectorProducts)
    newProducts = [...newProducts].sort((p1, p2) => {
      if (p1.sequenceNum === p2.sequenceNum) return 0
      if (p1.sequenceNum === null) return 1
      if (p2.sequenceNum === null) return -1
      return (p1.sequenceNum || 0) - (p2.sequenceNum || 0)
    })
    yield put(recipeAction.setReducerProductList(newProducts))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error createRecipeSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

// TODO: updateRecipeSaga

function* removeRecipeSaga({ payload: product }: ReturnType<typeof recipeActionSaga.removeRecipe>) {
  try {
    yield put(recipeAction.removeProduct(product))

    if (product.recipeId) {
      yield put(recipeAction.setSavingDataCount(1))
      yield call(deleteRecipe, product.recipeId)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error removeRecipeSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

function* addRecipeStemSaga({ payload }: ReturnType<typeof recipeActionSaga.addRecipeStem>) {
  yield put(recipeAction.startSkeletonRecipeStem(payload.stem.name))
  const stemOrderUnit: StemOrderUnit = {
    ...payload.stem,
    stemsNeeded: 1,
    bunchesNeeded: 1,
    totalStemPrice: 0
  }
  try {
    const { stem, product } = payload

    if (product.recipeId) {
      const response: AddRecipeStemPayloadResponse = yield call(addRecipeStem, product.recipeId, {
        stemId: stem.supplierStemId ? undefined : stem.id,
        supplierStemId: stem.supplierStemId,
        quantity: 1
      })

      const update: UpdateStemPayload = {
        recipeId: product.recipeId,
        stem: {
          ...stemOrderUnit,
          recipeStemId: response.id
        }
      }

      yield put(recipeAction.addStem(update))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error addRecipeStemSaga', error)
  }
  yield put(recipeAction.endSkeletonRecipeStem(payload.stem.name))
}

function* removeRecipeStemSaga({ payload }: ReturnType<typeof recipeActionSaga.removeRecipeStem>) {
  try {
    const { product, stem } = payload
    if (!product.recipeId) throw new Error(`Can't remove recipe stem with recipe id: ${product.recipeId}`)
    yield put(recipeAction.setSavingDataCount(1))
    yield put(
      recipeAction.removeStem({
        recipeId: product.recipeId,
        stem
      })
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield (call as any)(deleteRecipeStem, product.recipeId, stem.recipeStemId)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error removeRecipeStemSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

function* updateRecipeStemSaga({ payload }: ReturnType<typeof recipeActionSaga.updateRecipeStem>) {
  try {
    const { product, stem, stemNum } = payload

    const stemSelect = product.stems.find((item) => item.id === stem.id)

    if (!stemSelect) {
      throw new Error(`Can't update recipe stem`)
    }
    if (!product.recipeId) {
      throw new Error(`Can't update recipe stem with recipe id: ${product.recipeId}`)
    }

    const update: UpdateStemPayload = {
      recipeId: product.recipeId,
      stem: {
        ...stemSelect,
        stemsNeeded: stemNum ?? 1,
        bunchesNeeded: getUnitsNeeded(stem, +(stemNum || 1)),
        totalStemPrice:
          product?.quantityMode && product?.quantityMode === QuantityMode.ALL
            ? +(stemSelect.pricePerStem || 0)
            : +(stem.pricePerStem || 0) * +(stemNum || 1),
        stemRole: stem.stemRole,
        roleIndex: stem.roleIndex
      }
    }
    yield put(recipeAction.updateStemCount(update))

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // yield put(recipeActionSaga.callAPIUpdateRecipeStem(payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateRecipeStemSaga', error)
  }
}

function* callAPIUpdateRecipeStemSaga({ payload }: ReturnType<typeof recipeActionSaga.callAPIUpdateRecipeStem>) {
  try {
    yield put(recipeAction.setSavingDataCount(1))
    const { product, stem, stemNum } = payload

    if (!product.recipeId) {
      throw new Error(`Can't update recipe stem`)
    }

    yield call(updateRecipeStem, product.recipeId, stem.recipeStemId, {
      quantity: stemNum ? +stemNum : undefined,
      stemId: stem.supplierStemId ? undefined : stem.id,
      supplierStemId: stem.supplierStemId,
      stemRole: stem.stemRole || undefined,
      roleIndex: stem.roleIndex || undefined
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error callAPIUpdateRecipeStemSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

function* updateRecipeProductSaga({ payload }: ReturnType<typeof recipeActionSaga.updateRecipe>) {
  try {
    yield put(recipeAction.setSavingDataCount(1))
    const reducerProducts: ReducerProduct[] = yield select(selectorProducts)
    const eventOrderId: number = yield select(selectorEventOrderId)

    const productUpdate = reducerProducts.find((item) => item.recipeId === payload.recipeId)
    if (!productUpdate || !productUpdate.recipeId || productUpdate.recipeId < 0) {
      throw new Error(`Can't update recipe with recipe id : ${payload.recipeId}`)
    }

    yield call(updateRecipe, productUpdate.recipeId, {
      eventOrderId,
      productId: productUpdate.id,
      quantity: productUpdate.quantity,
      styleNotes: productUpdate.styleNotes ?? '',
      approved: productUpdate.approved ?? false,
      recipeStems: convertStemOrderUnitToRecipeStemPayload(productUpdate.stems),
      prepTimeMinutes:
        productUpdate.prepTimeMinutes || productUpdate.prepTimeMinutes === 0 || productUpdate.prepTimeMinutes === null
          ? !Number.isNaN(productUpdate.prepTimeMinutes)
            ? +(productUpdate.prepTimeMinutes || 0)
            : null
          : undefined,
      designTimeMinutes:
        productUpdate.designTimeMinutes ||
        productUpdate.designTimeMinutes === 0 ||
        productUpdate.designTimeMinutes === null
          ? !Number.isNaN(productUpdate.designTimeMinutes)
            ? +(productUpdate.designTimeMinutes || 0)
            : null
          : undefined,
      installTimeMinutes:
        productUpdate.installTimeMinutes ||
        productUpdate.installTimeMinutes === 0 ||
        productUpdate.installTimeMinutes === null
          ? !Number.isNaN(productUpdate.installTimeMinutes)
            ? +(productUpdate.installTimeMinutes || 0)
            : null
          : undefined,
      loadingTimeMinutes:
        productUpdate.loadingTimeMinutes ||
        productUpdate.loadingTimeMinutes === 0 ||
        productUpdate.loadingTimeMinutes === null
          ? !Number.isNaN(productUpdate.loadingTimeMinutes)
            ? +(productUpdate.loadingTimeMinutes || 0)
            : null
          : undefined,
      photosNeeded: productUpdate.photosNeeded ?? [],
      quantityMode: productUpdate.quantityMode ?? QuantityMode.EACH,
      imageOverride: productUpdate.imageOverride ?? '',
      nickName: productUpdate.nickName ?? ''
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateRecipeProductSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

function* handleAddProduct({ payload }: ReturnType<typeof recipeActionSaga.handleAddProduct>) {
  if (payload) {
    const products: ReducerProduct[] = yield select(selectorProducts)

    const productAlreadyExists = products.find((product) => product.id === payload.id)

    if (!productAlreadyExists) {
      yield put(recipeActionSaga.createRecipe(payload))
    }
  }

  yield put(recipeAction.setCurrentProduct(payload))
}

function* addRecipeHardGoodSaga({ payload }: ReturnType<typeof recipeActionSaga.addRecipeHardGood>) {
  // yield put(recipeAction.startSkeletonRecipeStem(payload.stem.name))
  try {
    const { product, hardGoods } = payload
    const payloadsAPI = hardGoods.map((hardGood) => {
      return {
        hardGoodId: hardGood.id || -1,
        quantity: 1,
        quantityType: hardGood.recipeQuantityType || ''
      }
    })

    if (product.recipeId) {
      const responses: RecipeHardGood[] = yield call(addRecipeHardGoodApi, product.recipeId, payloadsAPI)
      const updateHardGoods: UpdateHardGoodPayload[] = []
      responses.forEach((response) => {
        updateHardGoods.push({
          recipeId: product.recipeId || -1,
          recipeHardGood: response
        })
      })
      yield put(recipeAction.addHardGood(updateHardGoods))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error addRecipeHardGoodSaga', error)
  }
  // yield put(recipeAction.endSkeletonRecipeStem(payload.stem.name))
}

function* removeRecipeHardGoodSaga({ payload }: ReturnType<typeof recipeActionSaga.removeRecipeHardGood>) {
  try {
    const { product, recipeHardGood } = payload
    if (!product.recipeId) {
      throw new Error(`Can't update recipe hard good with recipe id: ${product.recipeId}`)
    }
    yield put(recipeAction.setSavingDataCount(1))
    yield put(
      recipeAction.removeHardGood({
        recipeId: product.recipeId,
        recipeHardGood
      })
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield (call as any)(deleteRecipeHardGoodApi, product.recipeId, recipeHardGood.id)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error removeRecipeHardGoodSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

function* updateRecipeHardGoodSaga({ payload }: ReturnType<typeof recipeActionSaga.updateRecipeHardGood>) {
  try {
    const { product, hardGood, quantity } = payload

    const hardGoodSelect = product.recipeHardGoods.find((item) => item.hardGood.id === hardGood.id)

    if (!hardGoodSelect) {
      throw new Error(`Can't update recipe hard good`)
    }

    if (!product.recipeId) {
      throw new Error(`Can't update recipe hard good with recipe id: ${product.recipeId}`)
    }

    const update: UpdateHardGoodPayload = {
      recipeId: product.recipeId,
      recipeHardGood: {
        ...hardGoodSelect,
        quantity: hardGoodSelect.quantity + quantity
      }
    }
    yield put(recipeAction.update1RecipeHardGoodData(update))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error updateRecipeHardGoodSaga', error)
  }
}

function* callAPIUpdateRecipeHardGoodSaga({
  payload
}: ReturnType<typeof recipeActionSaga.callAPIUpdateRecipeHardGood>) {
  try {
    yield put(recipeAction.setSavingDataCount(1))
    const { product, recipeHardGood, quantity, quantityType } = payload

    if (!product.recipeId || !recipeHardGood.id) {
      throw new Error(
        `Can't update recipe hard good with recipe id: ${product.recipeId} and hard good id: ${recipeHardGood.id}`
      )
    }

    const response: RecipeHardGood = yield call(updateRecipeHardGoodApi, product.recipeId, recipeHardGood.id, {
      hardGoodId: recipeHardGood.hardGoodId,
      quantity: quantity ? +quantity : 1,
      quantityType: quantityType || ''
    } as AddRecipeHardGoodPayload)
    yield put(
      recipeAction.update1RecipeHardGoodData({
        recipeId: product.recipeId,
        recipeHardGood: response
      })
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error callAPIUpdateRecipeStemSaga', error)
  } finally {
    yield put(recipeAction.setSavingDataCount(-1))
  }
}

export const recipeActionSaga = {
  getDetectedDifferenceRecipe: createAction<IRecipeCallAPI>('recipeActionSaga/getDetectedDifferenceRecipe'),
  updateRecipeStemSequenceNum: createAction<IUpdateRecipe>('recipeActionSaga/updateRecipeStemSequenceNum'),
  updateRecipeSequenceNum: createAction('recipeActionSaga/updateRecipeSequenceNum'),
  updateMultiRecipesForAssignsFormulaAndProductType: createAction<UpdateRecipeMultiPayload>(
    'recipeActionSaga/updateMultiRecipesForAssignsFormulaAndProductType'
  ),
  postOrders: createAction<IRecipeCallAPI>('recipeActionSaga/postOrders'),
  recipeCallAPI: createAction<IRecipeCallAPI>('recipeActionSaga/recipeCallAPI'),
  createRecipe: createAction<Product>('recipeActionSaga/createRecipe'),
  updateRecipe: createAction<IUpdateRecipe>('recipeActionSaga/updateRecipe'),
  updateRecipeStyleNotes: createAction<IUpdateRecipe>('recipeActionSaga/updateRecipeStyleNotes'),
  removeRecipe: createAction<ReducerProduct>('recipeActionSaga/removeRecipe'),
  addRecipeStem: createAction<IAddRecipeStemPayload>('recipeActionSaga/addRecipeStem'),
  removeRecipeStem: createAction<IRemoveRecipeStemPayload>('recipeActionSaga/removeRecipeStem'),
  updateRecipeStem: createAction<IUpdateRecipeStemPayload>('recipeActionSaga/updateRecipeStem'),
  callAPIUpdateRecipeStem: createAction<IUpdateRecipeStemPayload>('recipeActionSaga/callAPIUpdateRecipeStem'),
  getProducts: createAction('recipeActionSaga/getProducts'),
  handleAddProduct: createAction<Product | null>('recipeActionSaga/handleAddProduct'),
  addRecipeHardGood: createAction<IAddRecipeHardGoodPayload>('recipeActionSaga/addRecipeHardGood'),
  removeRecipeHardGood: createAction<IRemoveRecipeHardGoodPayload>('recipeActionSaga/removeRecipeHardGood'),
  updateRecipeHardGood: createAction<IUpdateRecipeHardGoodPayload>('recipeActionSaga/updateRecipeHardGood'),
  callAPIUpdateRecipeHardGood: createAction<IUpdateRecipeHardGoodAPIPayload>(
    'recipeActionSaga/callAPIUpdateRecipeHardGood'
  ),
  getStyleInformation: createAction<IStyleInformation>('recipeActionSaga/getStyleInformation'),
  updateRecipeQuantityMode: createAction<IUpdateRecipe>('recipeActionSaga/updateRecipeQuantityMode'),
  updateRecipeImageOverride: createAction<IUpdateRecipe>('recipeActionSaga/updateRecipeImageOverride')
}

export default [
  function* fetchWatcher() {
    yield all([
      takeEvery(recipeActionSaga.getDetectedDifferenceRecipe, ({ payload }) =>
        getDetectedDifferenceRecipeSaga(payload.eventId)
      ),
      takeEvery(recipeActionSaga.postOrders, ({ payload }) => postOrdersSaga(payload.eventId)),
      takeEvery(recipeActionSaga.getProducts, getProductsSaga),
      takeEvery(recipeActionSaga.recipeCallAPI, recipeCallAPISaga),
      takeEvery(recipeActionSaga.createRecipe, createRecipeSaga),
      takeLatest(recipeActionSaga.updateRecipe, updateRecipeProductSaga),
      debounce(DEBOUNCE_CALL_API_UPDATE, recipeActionSaga.updateRecipeStyleNotes, updateRecipeProductSaga),
      takeLatest(recipeActionSaga.updateRecipeStemSequenceNum, updateRecipeProductSaga),
      takeEvery(recipeActionSaga.removeRecipe, removeRecipeSaga),
      takeLatest(recipeActionSaga.updateRecipeSequenceNum, updateRecipeSequenceNumSaga),
      takeLatest(recipeActionSaga.updateMultiRecipesForAssignsFormulaAndProductType, ({ payload }) =>
        updateMultiRecipesForAssignsFormulaAndProductTypeSaga(payload)
      ),
      takeEvery(recipeActionSaga.addRecipeStem, addRecipeStemSaga),
      takeEvery(recipeActionSaga.removeRecipeStem, removeRecipeStemSaga),
      takeEvery(recipeActionSaga.callAPIUpdateRecipeStem, callAPIUpdateRecipeStemSaga),
      takeEvery(recipeActionSaga.updateRecipeStem, updateRecipeStemSaga),
      takeEvery(recipeActionSaga.handleAddProduct, handleAddProduct),
      takeEvery(recipeActionSaga.addRecipeHardGood, addRecipeHardGoodSaga),
      takeEvery(recipeActionSaga.removeRecipeHardGood, removeRecipeHardGoodSaga),
      takeEvery(recipeActionSaga.updateRecipeHardGood, updateRecipeHardGoodSaga),
      takeEvery(recipeActionSaga.callAPIUpdateRecipeHardGood, callAPIUpdateRecipeHardGoodSaga),
      takeEvery(recipeActionSaga.getStyleInformation, ({ payload }) => getStyleInformationSaga(payload.eventId)),
      takeEvery(recipeActionSaga.updateRecipeQuantityMode, updateRecipeProductSaga),
      takeEvery(recipeActionSaga.updateRecipeImageOverride, updateRecipeProductSaga)
    ])
  }
]
