import { EventInfo, EventModel, RecipeModel } from '../common/common.interface'
import { getHttp, putHttp, deleteHttp, postHttp } from './apiLayer'

interface ProposalInspirationPhotosPayLoad {
  id?: string
  imageUrl: string
  isHidden?: boolean
}

interface UpdateProposalInspirationPhotosPayLoad {
  isHidden?: boolean
}

export interface ProposalPayLoad {
  id?: string
  notes: string
  internalNotes: string
  proposalInspirationPhotos: ProposalInspirationPhotosPayLoad[]
}
export interface UpdateStyleInformationPayload {
  colors: string[]
  proposals: ProposalPayLoad[]
  pinterestBoard: string
  shape: string
}

export interface AirtableUpdatePayload {
  totalLabor?: number
  designerFeeNotes?: string
}

export interface IDifferenceRecipes {
  softDeleteRecipes: RecipeModel[]
  addNewRecipes: RecipeModel[]
  quantityUpdateRecipes: RecipeModel[]
  hasDifference: boolean
}

export interface IResyncParams {
  unitProductId?: number
  unitId?: number
  isOnlyUpdateQuantity?: boolean
}

export interface IBulkCreateRecipesPayload {
  updateRecipes: IResyncParams[]
  addNewRecipes: IResyncParams[]
}

export const getEventData = async (customerId: string | number): Promise<EventInfo | undefined> => {
  const result = await getHttp(`/getEventData/${customerId}`)
  return result.data
}

export const getStyleInformationByEventId = async (eventId: string | number): Promise<EventModel | undefined> => {
  const result = await getHttp(`/getStyleInformation/${eventId}`)
  return result.data
}

export const updateStyleInformationByEventId = async (
  eventId: string | number,
  payload: UpdateStyleInformationPayload
): Promise<EventModel | undefined> => {
  const result = await putHttp(`/updateStyleInformation/${eventId}`, payload)
  return result.data
}

export const removeProposalInspirationPhoto = async (
  proposalInspirationPhotoId: string | number
): Promise<boolean | undefined> => {
  const result = await deleteHttp(`/removeProposalInspirationPhoto/${proposalInspirationPhotoId}`)
  return result.data
}

export const updateProposalInspirationPhoto = async (
  proposalInspirationPhotoId: string | number,
  payload: UpdateProposalInspirationPhotosPayLoad
): Promise<ProposalInspirationPhotosPayLoad | undefined> => {
  const result = await putHttp(`/proposalInspirationPhoto/${proposalInspirationPhotoId}`, payload)
  return result.data
}

export const updateAirtableByEventId = async (
  eventId: string | number,
  payload: AirtableUpdatePayload
): Promise<EventModel | undefined> => {
  const result = await putHttp(`/update-airtable-by-event-id/${eventId}`, payload)
  return result.data
}

export const bulkCreateRecipesByEventId = async (
  eventId: string | number,
  payload?: IBulkCreateRecipesPayload
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any | undefined> => {
  const result = await postHttp(`/bulk-create-recipes/${eventId}`, payload ?? { updateRecipes: [], addNewRecipes: [] })
  return result.data
}

export const detectedDifferenceRecipesByEventId = async (eventId: string | number): Promise<IDifferenceRecipes> => {
  const result = await getHttp(`/detect-difference-recipes-from-proposal/${eventId}`)
  return result.data
}
