import { Product, RecipeHardGood, Stem, SupplierStem } from 'src/common/common.interface'
import { PhotosNeeded } from 'src/common/enum'
import { postHttp, deleteHttp, putHttp } from './apiLayer'
import { AddRecipeHardGoodPayload } from './recipeHardGoods'

export enum QuantityMode {
  EACH = 'EACH',
  ALL = 'ALL'
}

export interface CreateRecipeStemPayload {
  stemId: number
  quantity: number
  sequenceNum?: number
  stemRole?: string | null
  roleIndex?: number | null
}

export interface UpdateRecipeStemPayload {
  recipeStemId?: number
  stemId?: number
  supplierStemId?: number
  quantity: number
  sequenceNum?: number
}

export interface RecipesPayload {
  productId: number
  unitProductId?: number
  eventOrderId: number
  quantity: number
  styleNotes: string
  sequenceNum?: number
  approved?: boolean
  recipeStems?: UpdateRecipeStemPayload[]
  prepTimeMinutes?: number | null
  designTimeMinutes?: number | null
  installTimeMinutes?: number | null
  loadingTimeMinutes?: number | null
  photosNeeded?: string[]
  quantityMode?: QuantityMode
  imageOverride?: string
  nickName?: string
}

export interface IRecipeStem {
  stemId: number
  id: number
  supplierStemId?: number
  quantity: number
  stem?: Stem
  supplierStem?: SupplierStem
}

export interface CreateRecipesResponse {
  id: number
  productId: number
  eventOrderId: number
  quantity: number
  sequenceNum: number
  styleNote: string
  recipeStems: IRecipeStem[]
  recipeHardGoods: RecipeHardGood[]
  prepTimeMinutes?: number
  designTimeMinutes?: number
  installTimeMinutes?: number
  loadingTimeMinutes?: number
  photosNeeded?: PhotosNeeded[]
  product: Product
}

export interface IRecipeMultiPayloadPayload {
  recipeId: number
  sequenceNum?: number
  approved?: boolean
  styleNotes?: string
  prepTimeMinutes?: number | null
  designTimeMinutes?: number | null
  installTimeMinutes?: number | null
  loadingTimeMinutes?: number | null
  photosNeeded?: string[]
  productTypeId?: number
  formulaId?: number
  recipeStems?: CreateRecipeStemPayload[]
  recipeHardGoods?: AddRecipeHardGoodPayload[]
}

export interface UpdateRecipeMultiPayload {
  recipes: IRecipeMultiPayloadPayload[]
  case?: 'save-formulaId-productTypeId' | 'confirm-stemRoles'
}

export const createRecipe = async (payload: RecipesPayload): Promise<CreateRecipesResponse> => {
  const result = await postHttp(`/recipes`, payload)
  return result.data
}
export const deleteRecipe = async (recipeId: number) => {
  const result = await deleteHttp(`/recipes/${recipeId}`)
  return result.data
}

export const updateRecipe = async (recipeId: number, payload: RecipesPayload) => {
  const result = await putHttp(`/recipes/${recipeId}`, payload)
  return result.data
}

export const updateMultiRecipes = async (eventOrderId: number, payload: UpdateRecipeMultiPayload) => {
  const result = await putHttp(`/eventOrders/${eventOrderId}/recipes/multiples/`, payload)
  return result.data
}
