import { EventOrder, Product, ReducerProduct, Stem, StemOrderUnit, SupplierStem } from 'src/common/common.interface'
import { cloneDeep, intersection } from 'lodash'
import { IProductOfEventOrder, IStemOfEventOrder } from 'src/api/postOrders'
import {
  capitalizeTheFirstLetterOfEachWord,
  formatPrice,
  getProductDefaultImageUrl,
  getUnitPrice
} from 'src/common/util'
import { IRecipeStem, QuantityMode, UpdateRecipeStemPayload } from 'src/api/recipes'
import { GENERIC_SUPPLIER } from 'src/common/constants'
import { OrderState, PhotosNeeded, RecipeFormulaStemRole } from 'src/common/enum'

export const addOrUpdateProduct = (products: ReducerProduct[], newProduct: ReducerProduct): ReducerProduct[] => {
  const productAlreadyExists = products.find((product) => product.id === newProduct.id)

  if (productAlreadyExists) {
    return products.map((product) => {
      if (product.id === newProduct.id) {
        return {
          ...product,
          recipeId: product.recipeId ?? newProduct.recipeId,
          quantity: product.quantity + newProduct.quantity,
          sequenceNum: newProduct.sequenceNum,
          stems: newProduct.stems.length >= 0 ? newProduct.stems : product.stems,
          productDetails: newProduct.productDetails,
          recipeHardGoods: newProduct.recipeHardGoods || []
        }
      }

      return product
    })
  }

  return [...products, newProduct]
}

export const removeProduct = (products: ReducerProduct[], newProduct: ReducerProduct): ReducerProduct[] => {
  return products.filter((product) => product.recipeId !== newProduct.recipeId)
}

export interface IStemOrder extends StemOrderUnit {
  totalStem: number
  orderStemSupplier?: string
}

export interface ISummaryStem {
  [stemId: number]: IStemOrder
}

export const getSummary = (products: ReducerProduct[]): ISummaryStem => {
  const productsCopy = cloneDeep(products)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const summary: ISummaryStem = {}
  productsCopy.forEach((prod) => {
    prod.stems.forEach((stem) => {
      // const bunchesNeeded = getBunchesNeeded(stem.stemsNeeded, stem.bunchSize)
      const unitPrice = getUnitPrice(stem)
      if (!summary[stem.id]) {
        summary[stem.id] = {
          ...stem,
          totalStem: prod.quantityMode === QuantityMode.ALL ? +stem.stemsNeeded : prod.quantity * stem.stemsNeeded,
          totalStemPrice:
            prod.quantityMode === QuantityMode.ALL
              ? +stem.stemsNeeded * unitPrice
              : unitPrice * prod.quantity * stem.stemsNeeded
        }
      } else {
        summary[stem.id].totalStem +=
          prod.quantityMode === QuantityMode.ALL ? +stem.stemsNeeded : stem.stemsNeeded * prod.quantity
        summary[stem.id].totalStemPrice =
          (summary[stem.id].totalStemPrice || 0) +
          (prod.quantityMode === QuantityMode.ALL
            ? +stem.stemsNeeded * unitPrice
            : unitPrice * prod.quantity * stem.stemsNeeded)
      }
    })
  })

  return summary
}

export const filterStems = (allStems: Stem[], colors?: string[], suppliers?: string[]) => {
  let filteredStems = [...allStems]
  if (colors && colors.length > 0) {
    filteredStems = allStems.filter((item) => intersection(colors, item.color).length >= 1)
  }

  if (suppliers && suppliers.length > 0) {
    filteredStems = filteredStems?.filter((item) => intersection(suppliers, [item.supplier]).length >= 1)
  }

  return filteredStems || []
}

export const updateStemsNeededByQuantity = (
  stems: StemOrderUnit[],
  oldQuantity: number,
  newQuantity: number
): StemOrderUnit[] => {
  return stems.map((stem) => {
    const newStemsNeeded = Math.ceil((stem.stemsNeeded * newQuantity) / (oldQuantity || 1))
    return { ...stem, stemsNeeded: newStemsNeeded }
  })
}

export const updateProductQuantity = (
  products: ReducerProduct[],
  quantity: number,
  recipeId: number
): ReducerProduct[] => {
  return products.map((product) => {
    if (product.recipeId === recipeId) {
      return { ...product, quantity }
    }

    return product
  })
}

export const updateProductStyleNotes = (
  products: ReducerProduct[],
  styleNotes: string,
  recipeId: number
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    reducerProduct.styleNotes = styleNotes
  }

  return products
}

export const updateProductFieldsForDesignGuide = (
  products: ReducerProduct[],
  recipeId: number,
  fieldType: string,
  fieldValue?: string,
  fieldValueArray?: string[]
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    if (fieldType === 'photosNeeded') {
      reducerProduct.photosNeeded = fieldValueArray ? (fieldValueArray as PhotosNeeded[]) : []
    } else if (fieldType === 'prepTimeMinutes') {
      reducerProduct.prepTimeMinutes = fieldValue ? +fieldValue : null
    } else if (fieldType === 'designTimeMinutes') {
      reducerProduct.designTimeMinutes = fieldValue ? +fieldValue : null
    } else if (fieldType === 'installTimeMinutes') {
      reducerProduct.installTimeMinutes = fieldValue ? +fieldValue : null
    } else if (fieldType === 'loadingTimeMinutes') {
      reducerProduct.loadingTimeMinutes = fieldValue ? +fieldValue : null
    }
  }

  return products
}

export const updateProductQuantityMode = (
  products: ReducerProduct[],
  quantityMode: QuantityMode,
  recipeId: number
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    reducerProduct.quantityMode = quantityMode
  }

  return products
}

export const updateProductOverrideImage = (
  products: ReducerProduct[],
  imageOverride: string,
  recipeId: number
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    reducerProduct.imageOverride = imageOverride
  }

  return products
}

export const updateProductApproved = (
  products: ReducerProduct[],
  approved: boolean,
  recipeId: number
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    reducerProduct.approved = approved
  }

  return products
}

export const updateProductNickName = (
  products: ReducerProduct[],
  nickName: string,
  recipeId: number
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    reducerProduct.nickName = nickName
  }

  return products
}

export const changeStemOfRecipe = (
  products: ReducerProduct[],
  oldStemId: number,
  newStem: Stem,
  recipeId: number
): ReducerProduct[] => {
  const reducerProduct = products.find((product) => product.recipeId === recipeId)

  if (reducerProduct) {
    const oldStemIndex = reducerProduct.stems.findIndex((oStem) => oStem.id === oldStemId)
    const oldStem = reducerProduct.stems.find((oStem) => oStem.id === oldStemId)
    if (oldStem) {
      reducerProduct.stems[oldStemIndex] = {
        ...oldStem,
        ...newStem
      }
    }
  }

  return products
}

export const getUnitsNeeded = (stem: Stem, stemCount: number) => {
  const bunchSize = parseInt(stem?.bunchSize || '1')
  return Math.ceil(stemCount / bunchSize)
}

export const productPrice = (product: Product) => {
  return `${formatPrice((product.variants || [])[0]?.price)} ${(product.variants || [])[0]?.priceUnitDisplay || ''}`
}

export const convertSupplierStemToStem = (supplierStem: SupplierStem): Stem => {
  if (!supplierStem) return {} as Stem
  return {
    stemCategory: supplierStem.stemCategory,
    name: supplierStem.name,
    id: -1 * supplierStem.id,
    color: supplierStem.color,
    variety: '',
    type: [],
    externalId: supplierStem.externalId,
    recipeStemId: 0,
    supplierStemId: supplierStem.id,
    imageUrl: supplierStem.imageUrl,
    frequency: 1,
    pricePerStem: supplierStem.pricePerStem,
    pricePerBunch: supplierStem.pricePerBunch,
    bunchSize: supplierStem.bunchSize?.toString(),
    supplier: supplierStem.supplier
  }
}

export const convertStemOfEventOrderToStemOrderUnit = (
  stemOfEventOrders: IStemOfEventOrder[],
  product?: IProductOfEventOrder
): StemOrderUnit[] => {
  return stemOfEventOrders.map((stemOfEventOrder) => {
    const stem: Stem = stemOfEventOrder.stem ?? convertSupplierStemToStem(stemOfEventOrder.supplierStem as SupplierStem)

    return {
      ...stem,
      supplier: stem.supplier ?? GENERIC_SUPPLIER,
      recipeStemId: stemOfEventOrder.recipeStemId,
      stemsNeeded: stemOfEventOrder.quantity ?? 0,
      bunchesNeeded: getUnitsNeeded(stem, stemOfEventOrder.quantity),
      totalStemPrice:
        product?.quantityMode && product?.quantityMode === QuantityMode.ALL
          ? +(stem.pricePerStem || 0)
          : +(stem.pricePerStem || 0) * +(stemOfEventOrder.quantity || 0),
      stemRole: stemOfEventOrder.stemRole as RecipeFormulaStemRole,
      roleIndex: stemOfEventOrder.roleIndex,
      sequenceNum: stemOfEventOrder.sequenceNum
    }
  })
}

export const convertProductsEventOrderToReducerProducts = (data: IProductOfEventOrder[] = []): ReducerProduct[] => {
  return data.map((productOfEventOrder) => {
    return {
      ...productOfEventOrder,
      id: productOfEventOrder.id,
      // imageUrl: getProductDefaultImageUrl(productOfEventOrder?.images || []) ?? '',
      imageUrl:
        productOfEventOrder.unitProduct?.productImage?.imageUrl ??
        getProductDefaultImageUrl(productOfEventOrder?.images || []) ??
        '',
      images: productOfEventOrder?.images,
      name: productOfEventOrder.name,
      price: productPrice(productOfEventOrder),
      unitProductPrice: productOfEventOrder.unitProductPrice ? formatPrice(productOfEventOrder.unitProductPrice) : '',
      quantity: productOfEventOrder.quantity,
      recipeId: productOfEventOrder.recipeId,
      styleNotes: productOfEventOrder.styleNotes,
      stems: convertStemOfEventOrderToStemOrderUnit(productOfEventOrder.stems, productOfEventOrder),
      recipeHardGoods: productOfEventOrder.recipeHardGoods,
      unitProduct: productOfEventOrder.unitProduct
    }
  })
}

export const convertStemOrderUnitToRecipeStemPayload = (data: StemOrderUnit[]): UpdateRecipeStemPayload[] => {
  return data.map((item, index) => {
    return {
      quantity: +item.stemsNeeded,
      recipeStemId: item.recipeStemId,
      stemId: item.supplierStemId ? undefined : item.id,
      supplierStemId: item.supplierStemId,
      sequenceNum: index
    }
  })
}

export const convertColorStyleToColorFilter = (colors: string[]): string[] => {
  return colors.map(capitalizeTheFirstLetterOfEachWord)
}

export const convertIRecipeStemToStemOfEventOrder = (data: IRecipeStem[]): IStemOfEventOrder[] => {
  return data.map((item) => ({
    ...item,
    recipeStemId: item.id
  }))
}

export const convertIRecipeStemToStemOrderUnit = (data: IRecipeStem[]): StemOrderUnit[] => {
  const formatData = convertIRecipeStemToStemOfEventOrder(data)
  return convertStemOfEventOrderToStemOrderUnit(formatData)
}

export const filteredSubmittedOrderSuppliers = (eventOrder: EventOrder) => {
  const orderSuppliers = eventOrder?.orderSuppliers || []
  return orderSuppliers.filter((os) => os.orderState === OrderState.SUBMITTED)
}
