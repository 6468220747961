/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterOptionsState } from '@material-ui/lab'
import { matchSorter } from 'match-sorter'
import Fuse from 'fuse.js'
import moment from 'moment'
import {
  EventLocation,
  ProductImage,
  RecipeHardGood,
  ReducerProduct,
  Stem,
  StemOrderUnit,
  SupplierStemOrderUnit
} from './common.interface'
import { ProductImageType } from './enum'

const heicExtRegex = /(\.(heic|heif)$|(image\/heic))/i

export interface SummaryRecipeHardGood extends RecipeHardGood {
  totalHardGood: number
  totalPrice: number
  orderHardGoodSupplier?: string
}

export const formatCurrency = (val: string | number | undefined) => {
  let num = val
  if (typeof val === 'string') {
    num = parseFloat(val)
  }
  if (!val) {
    num = 0
  }
  return Intl.NumberFormat('en-IN', { style: 'currency', currency: 'USD' }).format(num as number)
}

export const formatPrice = (val: number | undefined) => {
  if (val === undefined) {
    return ''
  }
  const price = val / 100
  return formatCurrency(price)
}

export const getBunchesNeeded = (stemCount: string | number, bunchSize?: string) => {
  const bunchS = parseInt(bunchSize || '1')
  let stemC = stemCount
  if (typeof stemC === 'string') {
    stemC = parseInt(stemC)
  }
  return Math.ceil(stemC / bunchS)
}

export const getTotalEstimate = (stem: SupplierStemOrderUnit | StemOrderUnit | Stem, bunchesNeeded: number) => {
  let unitPrice
  if (stem.bunchSize) {
    unitPrice = parseFloat(stem.pricePerBunch || '0')
  } else {
    unitPrice = parseFloat(stem.pricePerStem || '0')
  }
  return `$${(bunchesNeeded * unitPrice).toFixed(2)}`
}

export const getUnitPrice = (stem: SupplierStemOrderUnit | StemOrderUnit | Stem) => {
  let unitPrice
  if (stem.bunchSize) {
    unitPrice = parseFloat(stem.pricePerBunch || '0')
  } else {
    unitPrice = parseFloat(stem.pricePerStem || '0')
  }
  return +unitPrice
}

export const onlyUnique = <T>(data: T[]): T[] => {
  return data.filter((value, index, self) => {
    return self.indexOf(value) === index
  })
}

export const capitalizeTheFirstLetterOfEachWord = (words: string) => {
  return words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

export const formatMonths = (months: string[] | undefined) => {
  if (!months || months.length < 2) {
    return months
  }
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  let expectedIndex = monthNames.indexOf(months[0]) + 1
  for (let i = 1; i < months.length; i++) {
    if (monthNames.indexOf(months[i]) !== expectedIndex) {
      return months
    }
    expectedIndex++
  }

  return `${months[0]} - ${months[months.length - 1]}`
}

export function fuzzySearchMultipleWords<ItemType = string>(
  rows: ItemType[], // array of data [{a: "a", b: "b"}, {a: "c", b: "d"}]
  keys: string[], // keys to search ["a", "b"]
  filterValue: string // potentially multi-word search string "two words"
): Array<ItemType> {
  if (!filterValue || !filterValue.length) {
    return rows
  }

  const terms = filterValue.split(' ')
  if (!terms) {
    return rows
  }

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  return terms.reduceRight((results, term) => matchSorter(results, term, { keys }), rows)
}

export const filterOptions = <T = any>(options: T[], state: FilterOptionsState<T>) => {
  return fuzzySearchMultipleWords(options, ['name'], state.inputValue)
}

export const filterOptionsFilteredDropdown = <T = any>(options: T[], state: FilterOptionsState<T>) => {
  return fuzzySearchMultipleWords(options, ['label'], state.inputValue)
}

export const filterOptionsStem = <T = any>(rows: T[], state: FilterOptionsState<T>) => {
  const configOptionStem = {
    includeScore: true,
    useExtendedSearch: true,
    keys: ['name', 'pricePerStem']
  }
  const filterValue = state.inputValue
  if (!filterValue || !filterValue.length) {
    return rows
  }

  const terms = filterValue.split(' ')
  if (!terms) {
    return rows
  }
  const fuse = new Fuse(rows, configOptionStem)
  return fuse
    .search(
      state.inputValue
        .split(' ')
        ?.filter((i) => i)
        ?.map((is) => `'${is}`)
        .join(' | ')
    )
    .map((i) => i.item)
}

export const copyElementToClipboard = (element: string) => {
  try {
    window.getSelection()?.removeAllRanges()
    const range = document.createRange()
    range.selectNode(document.getElementById(element) as HTMLElement)
    window.getSelection()?.addRange(range)
    document.execCommand('copy')
    window.getSelection()?.removeAllRanges()
    return true
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error', error)
    return false
  }
}
export interface Params {
  [key: string]: any
}

export const ObjectToFormData = (params: Params = {}) => {
  const formData = new FormData()
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key])
  })
  return formData
}

export const formatDateToMDY = (date?: string) => {
  if (!date) {
    return
  }

  return new Date(date).toLocaleDateString('en-US')
}

export const formattedDateToMMDDYYYY = (date?: string) => {
  if (!date) {
    return
  }

  return moment(date).format('MM/DD/YYYY')
}

export const getProductDefaultImageUrl = (images: ProductImage[]) => {
  if (!images || (images && !images.length)) return ''
  const defaultImage = images.find((image) => image.type === ProductImageType.DEFAULT)
  if (defaultImage) return defaultImage.imageUrl
  return ''
}

export const getSummaryHardGoodsFromProduct = (products: ReducerProduct[]): SummaryRecipeHardGood[] => {
  const summaryRecipeHardGoodsTemp: SummaryRecipeHardGood[] = []
  if (products && products.length) {
    products.map((p) => {
      p.recipeHardGoods?.map((rhg) => {
        const ind = summaryRecipeHardGoodsTemp.findIndex((srhg) => srhg.hardGoodId === rhg.hardGoodId)
        if (ind > -1) {
          summaryRecipeHardGoodsTemp[ind] = {
            ...rhg,
            quantity: summaryRecipeHardGoodsTemp[ind].quantity + rhg.quantity,
            totalHardGood: summaryRecipeHardGoodsTemp[ind].totalHardGood + p.quantity * rhg.quantity,
            totalPrice:
              summaryRecipeHardGoodsTemp[ind].totalPrice + p.quantity * rhg.quantity * +(rhg.hardGood?.price || 0)
          }
        } else {
          summaryRecipeHardGoodsTemp.push({
            ...rhg,
            totalHardGood: p.quantity * rhg.quantity,
            totalPrice: p.quantity * rhg.quantity * +(rhg.hardGood?.price || 0)
          })
        }
        return rhg
      })
      return p
    })
  }
  return summaryRecipeHardGoodsTemp
}

export const nl2br = (str: string) => {
  if (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
  }
  return ''
}

export const rmnl = (str: string) => {
  if (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '')
  }
  return ''
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const keyTypeToHumanReadable = (keyType: string) => {
  if (!keyType) return ''
  return keyType
    .replace(/_/g, ' ')
    .split(/\s+/)
    .map((w) => w[0]?.toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')
}

export const roundTo = (value?: number, fractionDigits?: number | undefined): number | undefined => {
  if (!value) return value
  const fraction = 10 ** (fractionDigits || 0)
  return Math.round(value * fraction) / fraction
}

export const makeId = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const roundTotalToNext15 = (totalMinutes: number, returnNumber?: boolean) => {
  let h = Math.floor(totalMinutes / 60)
  const m = totalMinutes % 60
  let roundM = m
  if (m <= 15) {
    roundM = 15
  } else if (m <= 30) {
    roundM = 30
  } else if (m <= 45) {
    roundM = 45
  } else {
    roundM = 0
    h += 1
  }
  if (returnNumber) {
    return h * 60 + roundM
  }
  return `${h} ${h > 1 ? 'hrs' : 'hr'} ${roundM} ${roundM > 1 ? 'mins' : 'min'}`
}

export const round5 = (aNumber: number) => {
  return Math.ceil(aNumber / 5) * 5
}

export const roundTotalToNext5 = (totalMinutes: number, returnNumber?: boolean) => {
  let h = Math.floor(totalMinutes / 60)
  const m = totalMinutes % 60
  let roundM = round5(m)
  if (roundM >= 60) {
    roundM = 0
    h += 1
  }
  if (returnNumber) {
    return h * 60 + roundM
  }
  return `${h} ${h > 1 ? 'hrs' : 'hr'} ${roundM} ${roundM > 1 ? 'mins' : 'min'}`
}

export const round = (value: number, precision: number) => {
  // eslint-disable-next-line no-restricted-properties
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const isHeicType = (url: string) => {
  return url.match(heicExtRegex) !== null
}

export const getAlphabetCharacter = (index?: number | null, isUpperCase = false) => {
  if (typeof index !== 'number') {
    return ''
  }
  const baseCode = isUpperCase ? 65 : 97 // 65 for 'A', 97 for 'a'
  return String.fromCharCode(baseCode + index - 1)
}

export const getAllEventServicesInText = (allLocations: EventLocation[]) => {
  return allLocations.map((l) => l.eventServices?.map((es) => keyTypeToHumanReadable(es.type || ''))).join(', ')
}

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
  window.matchMedia('only screen and (max-width: 700px)').matches
