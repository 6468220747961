import { EventOrder, Product, RecipeHardGood, Stem, SupplierStem, UnitProduct } from 'src/common/common.interface'
import { PhotosNeeded } from 'src/common/enum'
import { postHttp } from './apiLayer'
import { QuantityMode } from './recipes'

export interface IStemOfEventOrder {
  stemId: number
  recipeStemId: number
  supplierStemId?: number
  quantity: number
  stem?: Stem
  supplierStem?: SupplierStem
  stemRole?: string
  roleIndex?: number
  sequenceNum?: number
}

export interface IRecipeTemplateOfEventOrder {
  id: number
  stems: IStemOfEventOrder[]
}
export interface IProductOfEventOrder extends Product {
  id: number
  name: string
  recipeId: number
  unitProductPrice: number
  quantity: number
  styleNotes: string
  stems: IStemOfEventOrder[]
  recipeTemplate?: IRecipeTemplateOfEventOrder[]
  recipeHardGoods: RecipeHardGood[]
  prepTimeMinutes?: number
  designTimeMinutes?: number
  installTimeMinutes?: number
  loadingTimeMinutes?: number
  photosNeeded?: PhotosNeeded[]
  quantityMode?: QuantityMode
  unitProduct: UnitProduct | null
}

export interface EventOrderResponse {
  eventOrderId: number
  eventOrder: EventOrder
  lastSyncedDate?: string
  products: IProductOfEventOrder[]
}

export const postOrders = async (eventId: number): Promise<EventOrderResponse> => {
  const result = await postHttp(`/eventOrders`, {
    eventId
  })
  return result.data
}
